import React, { useEffect, useState } from "react";
import { isEmpty } from 'lodash';
import Img from "gatsby-image"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import { removeHost } from "../../utils/functions";
import ReactHtmlParser from 'react-html-parser';
import Banners from "../../components/banners/Banners";
import ProductsList from "../../components/productsList/ProductsList";
import CalendaIcon from '../../images/post_calendar.svg'
import "./style.scss"
import PostList from "../../components/postsList/PostsList";
import { WPViewContent } from "../../utils/WPpixel";

const PostSinglePage = (props) => {
  const {
    pageContext: {
      title, seo, uri, content, id, acfPost, featuredImage, date
    }
  } = props;
  
  useState(()=>{
    WPViewContent('View');
  },[]) 

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);
  const [productsItems, setProductsItems] = useState([])
  const postData = new Date(date);
  useEffect(() => {
    if (acfPost.products) {

      let tmp = [];
      acfPost.products.forEach(element => {
        tmp.push({ ...element.product })
      });
      setProductsItems(tmp)
    }
  }, [])

  // 
  function transform(node, index) {
    if (node.type === "tag" && node.name === "section" && node.attribs.class === "acf-banners") {
      return <Banners key={'transform_' + index} lang={lang} count={node.attribs['data-bannerscount']} />;
    }
    if (node.type === "tag" && node.name === "section" && node.attribs.class === "acf-products") {
      // console.log(acfPost);
      if (productsItems) {
        return <ProductsList key={'transform_' + index} action='add' productBoxButton={acfPost.textButton} products={productsItems} columns={3} />;
      }
    }
  }

  const options = {
    decodeEntities: true,
    transform
  };
  const htmlNodes = ReactHtmlParser(removeHost(content), options);
  return (
    <Layout lang={lang} page="post" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"post"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <article id={`article-${id}`}>
              <section className="post__hero" >
                {featuredImage && (
                  <div className="post__hero__img">
                    <Img fluid={featuredImage.node.imageFile.childImageSharp.fluid} />
                  </div>
                )}
                <div className="post__hero__apla">
                </div>
                <div className="container container--small">
                  <div className="post__hero__heading">
                    <h1 className="heading heading--xxl">
                      {acfPost.fomatedTitle ? (
                        <span dangerouslySetInnerHTML={{ __html: acfPost.fomatedTitle }} />
                      ) : (
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                      )}
                    </h1>
                  </div>
                </div>
              </section>
              {date && (
                <div className="container container--small">
                  <div className="post__date">
                    <CalendaIcon />
                    <span>
                      {postData.getDate()}.{postData.getMonth()}.{postData.getFullYear()}
                    </span>
                  </div>
                </div>
              )}
              <div className="container container--small">
                <div className="post__content">
                  {htmlNodes}
                </div>
              </div>
            </article>
            <div className="container post__morePost">
              <PostList id={id} />
            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default PostSinglePage;

