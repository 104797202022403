import React from 'react';
import "./style.scss"
import New from "../../images/tag_new.svg"
import Bestseller from "../../images/tag_bestseller.svg"


const Tags = ({ tags }) => {
  return (
    <div className="tags">
      <div className={`tags__wrapper`}>
        {tags.map((tag, index) => (
          <div key={'tag_' + index} className={`tags__item tags__item--${tag.slug}`}>
            {tag.slug === 'nowosc' || tag.slug === 'new' ? (<New />) : (<Bestseller />)}
            {tag.name}
          </div>
        ))}
      </div>
    </div>
  )
}
export default Tags
