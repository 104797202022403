import React, { useContext } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Button from "../button/Button"
import Tags from "../tags/Tags"
import ButtonAddToCart from "../buttonAddToCart/ButtonAddToCart"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { AppContext } from "../context/AppContext"
import { priceToFloat } from "../../utils/functions"

const ProductsListBox = ({
  img,
  name,
  link,
  textButton,
  tags,
  priceRegular,
  salePrice,
  prodcutId,
  action = "link",
}) => {
  const { addToCartError, addToCartErrorIdProduct } = useContext(AppContext)

  let floatPrice = priceToFloat(priceRegular)
  if (salePrice) {
    floatPrice = priceToFloat(salePrice)
  }

  const dataProductForPixel = {
    id: prodcutId,
    name: name,
    price: floatPrice,
    quantity: 1,
    category: "Żywność, napoje i tytoń > Żywność",
  }

  return (
    <div className="productsListBox">
      <div className="productsListBox__img">
        <Link to={link}>
          {img.imageFile ? (
            <Img fluid={img.imageFile.childImageSharp.fluid} />
          ) : (
            <LazyLoadImage
              src={img} // use normal <img> attributes as props
              effect="blur"
              height="320"
              width="320"
            />
          )}
        </Link>
      </div>
      {tags && (
        <div className="productsListBox__tags">
          <Tags tags={tags} />
        </div>
      )}
      <h3 className="productsListBox__title heading heading--normal">
        <Link to={link}>{name}</Link>
      </h3>
      <div className="productsListBox__price">
        {salePrice ? (
          <>
            <span className="productsListBox__price--new">
              <span dangerouslySetInnerHTML={{ __html: salePrice }}></span>
            </span>
            <del className="productsListBox__price--old">
              <span dangerouslySetInnerHTML={{ __html: priceRegular }}></span>
            </del>
          </>
        ) : (
          <span className="productsListBox__price--normal">
            <span dangerouslySetInnerHTML={{ __html: priceRegular }}></span>
          </span>
        )}
      </div>
      <div className="productsListBox__button">
        {action === "link" ? (
          <>
            <Button title={textButton} url={link} />
            <ButtonAddToCart
              // title={textButton}
              quantity={1}
              productID={prodcutId}
              icon={true}
              dataProductForPixel={dataProductForPixel}
            />
            {addToCartError && +prodcutId === addToCartErrorIdProduct ? (
              <p className="productSingleHero__buy__cant">{addToCartError}</p>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <ButtonAddToCart
              title={textButton}
              quantity={1}
              productID={prodcutId}
              dataProductForPixel={dataProductForPixel}
            />
            {addToCartError && +prodcutId === addToCartErrorIdProduct ? (
              <p className="productSingleHero__buy__cant">{addToCartError}</p>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  )
}
export default ProductsListBox
