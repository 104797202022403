import React from 'react';
import { PostsDataPL } from './hookPL';
import "./style.scss"
import { withPrefix } from 'gatsby';
import PostsListbox from './PostsListbox';


const PostList = ({ id }) => {
  const data = PostsDataPL();
  return (
    <section className="postsList"
      style={{
        backgroundImage: `url(${withPrefix('/images/product_cat_bg.png')})`,
        backgroundPosition: 'left top',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
      }}
    >
      <div className="container">
        <h2 className="postsList__heading heading--dash heading--center heading--big">
          {data.themesOptionsPage.template.post.hedingPosts}
        </h2>
        <div className="postsList__wrapper grid grid--3">
          {data.posts.nodes.map((post, index) => (
            post.id === id ? ('') : (
              <PostsListbox
                key={post.id}
                img={post.featuredImage.node}
                name={post.title}
                link={post.uri}
                date={post.date}
                excerpt={post.excerpt}
              />
            )
          ))}
        </div>
      </div>
    </section>
  )
}
export default PostList
