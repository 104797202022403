import { useStaticQuery, graphql } from "gatsby"
export const PostsDataPL = () => {
  const dataPL = useStaticQuery(
    graphql`
      query Posts {
        wpgraphql {
          posts(first: 4, where: {wpmlLanguage: "pl"}) {
            nodes {
              date
              title
              uri
              excerpt
              id
              featuredImage {
                node {
                  sourceUrl
                  databaseId
                  modified
                }
              }
            }
          }
          themesOptionsPage {
            template {
              post{
                hedingPosts
              }
            }
          }
        }
      }
    `
  )

  return dataPL.wpgraphql
}
