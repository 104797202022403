import React from 'react';
import Img from "gatsby-image"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from 'gatsby';
import CalendaIcon from '../../images/post_calendar.svg'


const PostsListbox = ({ img, name, link, date, excerpt }) => {
  const postData = new Date(date);

  String.prototype.trimEllip = function (length) {
    return this.length > length ? this.substring(0, length) + "..." : this;
  }

  return (
    <div className="postsListbox">
      {img ? (
        <div className="postsListbox__img">
          <Link to={link} >
            {img.imageFile ? (
              <Img fluid={img.imageFile.childImageSharp.fluid} />
            ) : (
              <LazyLoadImage
                alt={name}
                src={img.sourceUrl} // use normal <img> attributes as props
                effect="blur"
                loading="lazy"
              />
            )}
          </Link>
        </div>
      ) : (
        <></>
      )}
      <div className="postsListbox__content">
        {date && (
          <div className="postsListbox__date">
            <CalendaIcon />
            <span>
              {postData.getDate()}.{postData.getMonth()}.{postData.getFullYear()}
            </span>
          </div>
        )}
        <h3 className="postsListbox__title heading heading--normal">
          <Link to={link} >
            {name}
          </Link>
        </h3>
        {excerpt && (
          <div className="postsListbox__excerpt">
            <p dangerouslySetInnerHTML={{ __html: excerpt.trimEllip(75) }}></p>
          </div>
        )}
      </div >
    </div >
  )
}
export default PostsListbox
